<template>
<div>
  <div class="row">
    <div class="col-md-12">
        <div class="card card-outline card-primary">
              <div class="card-header">
                <h2 class="card-title">Bestellverlauf</h2>
                <div class="card-tools">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Firma</label>
                        <div class="col-md-9">
                          <select class="form-control form-control-sm" v-model="params.company_id" @change="changeCompany">
                            <option :value="null">-- Filter Firma --</option>
                            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                          </select>
                        </div>   
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Kundengruppe</label>
                        <div class="col-md-9">
                          <select class="form-control form-control-sm" v-model="params.customer_group_id">
                            <option :value="null">-- Filter Kundengruppe --</option>
                            <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.description }}</option>
                          </select>
                        </div>   
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Zahlart</label>
                        <div class="col-md-9">
                          <select class="form-control form-control-sm" v-model="params.payment_id">
                            <option :value="null">-- Filter Zahlart --</option>
                            <option v-for="payment in payments" :key="payment.id" :value="payment.id">{{ payment.description }}</option>
                          </select>
                        </div>   
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Versandart</label>
                        <div class="col-md-9">
                          <select class="form-control form-control-sm" v-model="params.dispatch_id">
                            <option :value="null">-- Filter Versandart --</option>
                            <option v-for="dispatch in dispatches" :key="dispatch.id" :value="dispatch.id">{{ dispatch.description }}</option>
                          </select>
                        </div>   
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Geräteart</label>
                        <div class="col-md-9">
                          <select class="form-control form-control-sm" v-model="params.device_id">
                            <option :value="null">-- Filter Geräteart --</option>
                            <option v-for="device in devices" :key="device" :value="device">{{ device }}</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Von:</label>
                        <div class="col-md-9">
                          <input type="date" class="form-control form-control-sm" v-model="params.start_date" />
                        </div>   
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Bis:</label>
                        <div class="col-md-9">
                          <input type="date" class="form-control form-control-sm" v-model="params.stop_date" />
                        </div>  
                      </div>
                    </div>

                    <div class="col-md-2">
                      <button class="btn btn-primary btn-sm" @click="loadSales"><i class="fas fa-sync"></i></button>
                    </div>

                    
                  </div>
                  
                </div>
              </div>

              <div class="card-body">
                    <Chart v-if="loaded == true" :chartData="chartdata" style="height: 350px; width: 100%;"/>        
              </div>
        </div>
        
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <h2 class="card-title">Umsätze</h2>
          </div>

          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <th>#</th>
                <th>Umsatz(Brutto)</th>
                <th>Einkauf(Brutto)</th>
                <th>Marge (Brutto)</th>
                <th>Umsatz(Netto)</th>
                <th>Einkauf(Netto)</th>
                <th>Marge(Netto)</th>
                <th>Menge (Artikel)</th>
                <th>Menge (Bestellung)</th> 
              </thead>
              <tbody>
                <tr>
                  <td>Aktueller Zeitraum</td>
                  <td>{{ total | toCurrency }}</td>
                  <td>{{ einkauf | toCurrency }}</td>
                  <td>{{ total - einkauf | toCurrency }}</td>
                  <td>{{ total_net | toCurrency }}</td>
                  <td>{{ einkauf_net | toCurrency }}</td>
                  <td>{{ total_net - einkauf_net | toCurrency }}</td>
                  <td>{{ qty }} Stk.</td>
                  <td>{{ order_qty }} Stk.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
import Chart from './components/SalesChart';
export default {
    name: "StatisticsSales",
    title: "Umsätze",

    components: {
        Chart,
    },

    data(){
        return{
            groups: null,
            companies: [],
            dispatches: null,
            payments: null,
            devices: [
              "Desktop",
              "Tablet",
              "Handy"
            ],
            params: {
                customer_group_id: null,
                company_id: null,
                start_date: null,
                stop_date: null,
                payment_id: null,
                dispatch_id: null,
                device_id: null,
            },    
            loaded: false,
            orders: [],      
            chartdata: {
                'labels': '',
                'data': '',
            },
            total: 0,
            total_net: 0,
            einkauf: 0,
            einkauf_net: 0,
            qty: 0,
            options: '',
            order_qty: 0,
        }
    },

    methods:{
      changeCompany(){
        var company = this.companies.find(item => item.id == this.params.company_id);
        if(company == undefined)
        {
          this.groups = null;
          this.dispatches = null;
          this.params.customer_group_id = null;
          this.params.dispatch_id = null;
          this.params.payment_id = null;
          this.params.device_id = null;
        }
        else
        {
          this.groups = company.groups;
          this.dispatches = company.dispatches;
        }
        
      },

    loadSales(){
        this.loaded = false;
            this.axios
            .post("/statistics/sales", {
              start_date: this.params.start_date,
              stop_date: this.params.stop_date,
              customer_group_id: this.params.customer_group_id,
              company_id: this.params.company_id,
              payment_id: this.params.payment_id,
              dispatch_id: this.params.dispatch_id,
              device_id: this.params.device_id,
              
            })
            .then((response) => {
                //this.orders = response.data.data.orders;
                this.chartdata.labels = response.data.data.labels;
                this.chartdata.data = response.data.data.amount;
                this.total = response.data.data.total.umsatz;
                this.total_net = response.data.data.total.umsatz_net;
                this.einkauf = response.data.data.total.einkauf;
                this.einkauf_net = response.data.data.total.einkauf_net;
                this.qty = response.data.data.total.qty;
                this.order_qty = response.data.data.total.order_qty;
                this.loaded = true;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.loaded = false;
            });
        },

        loadCompanies()
        {
          this.axios
            .get("/companies")
            .then((response) => {
                this.companies = response.data.data;
                //this.groups = response.data.data[0].groups;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },

        loadPayments()
        {
          this.axios
            .get("/payments")
            .then((response) => {
                this.payments = response.data.data;
            })
            .catch(() => {
              this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
              });
            });
        },

        loadCustomergroups()
        {
          this.axios
            .get("/customer-groups")
            .then((response) => {
                this.groups = response.data.data;
            })
            .catch(() => {
              this.$swal({                
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
              });
            });
        }
    },

    mounted(){
        this.params.start_date = moment().subtract(1, 'month').format('Y-MM-DD');
        this.params.stop_date =  moment().format('Y-MM-DD');
        this.loadSales();
        this.loadCompanies();
        this.loadPayments();
        this.loadCustomergroups();
    }
}
</script>

<style>

</style>